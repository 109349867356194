import React from 'react';
import ReactDOM from 'react-dom/client';
import "../src/assets/scss/main.scss"
import App from './App';
import { BrowserRouter } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

