import axios from "axios";
import { constants } from "../util/Constants";
let { host, host_redirect, host_admin } = constants;
const crypto = require('crypto-js');
const queryString = require('qs');
const ENC_KEY = "7x!A%D*G-JaNdRgUkXp2s5v8y/B?E(H+"; // set random encryption key
const IV = "ABCDEFGHIJKLMNOP"; // set random initialisation vector

var encrypt = ((val) => {
  let cipher = crypto.createCipheriv('aes-256-cbc', ENC_KEY, IV);
  let encrypted = cipher.update(val, 'utf8', 'base64');
  encrypted += cipher.final('base64');
  return encrypted;
});

function aesEncrypt(data) {
  let cipher = crypto.AES.encrypt(data, ENC_KEY, {
      iv: IV,
      mode: crypto.mode.CBC,
      padding: crypto.pad.Pkcs7
  });
  return cipher.toString();
}

export let Createtranx = (state, setState, resp, setResp, redirect) => {
  let content = aesEncrypt(JSON.stringify(state));
    let data = {
      lob_id: state.payment_data.lob_id,
      payment_source_id: state.payment_data.payment_source_id,
      request_id: state.payment_data.request_id,
      request_date: state.payment_data.request_date,
      content: content
    }
    axios
        .post(`${host}/api/plorder/create`, data)
        .then((res) => {
          if (!res.data.success) {
            let ext = res.data.error.map((k) => k + "\n").join(",\n");

            return window.swal(ext, "", "error");
          }
          setResp(res.data);
          window.swal("Created", "", "success");
          if (redirect) {
            window.open(res.data.response.links.web_url);
            // window.open(`${host_redirect}` + res.data.response.pl_order_id);
          }
        })
        .catch((err) => {
          setResp(err);
          window.swal("", "", "error");
        });
  // axios
  //   .post(`${host}/api/web/encrypt`, state)
  //   .then((res) => {
      
  //   })
  //   .catch((err) => {
  //     setResp(err);
  //     window.swal(
  //       "Failed to perform this operation please try again",
  //       "",
  //       "error"
  //     );
  //   });
};

export let getResult = (pl_order_id, state, setState, final) => {
  axios
    .get(`${host}/api/web/processJuspayResponse/${pl_order_id}`, {
      headers: {
        lob_id: window.location.pathname.split("/")[2],
        payment_source_id: window.location.pathname.split("/")[3],
      },
    })
    .then(function (response) {
      let data = response.data;
      setState(data);
    });
};

export let getAllNode = (lob_code, setState, trigger, setTrigger) => {
  axios
    .post(`${host}/api/admin/master/sourcenode/all`, {
      lob_code: lob_code,
    })
    .then(function (response) {
      let data = response.data;
      setState(data);
    });
};

export let getAllField = (
  lob_code,
  payment_methods,
  setState,
  trigger,
  setTrigger
) => {
  axios
    .post(`${host}/api/admin/master/sourcefield/all`, {
      lob_code: lob_code,
      payment_methods: payment_methods,
    })
    .then(function (response) {
      let data = response.data;
      setState(data);
      setTrigger(!trigger);
    });
};
