import axios from "axios";
import { constants } from "../util/Constants";
import { toast } from "react-toastify";
let { host, uihost } = constants;

  export let getLinkGenOrderDetail=(enc_data,state,setState,setattempt)=>{
    axios.get(`${host}/api/web/getLinkGenOrderData/${window.location.pathname.split("/")[2]}/${window.location.pathname.split("/")[3]}/${window.location.pathname.split("/")[4]}`,{
      headers: {
        lob_id: window.location.pathname.split("/")[2],
        payment_source_id: window.location.pathname.split("/")[3],
      },
    }).then((res)=>{
      let data=res.data;
      setState(data);
      setattempt(res.data.attemptData);
    }).catch((error)=>{
      return error
    // window.location.assign(`${uihost}/maintenanceerror`);
    })
  
  }

  
export const createLinkGenOrder = (enc_data) => {
  
  const headers = {
    lob_id: window.location.pathname.split("/")[2],
    payment_source_id: window.location.pathname.split("/")[3],
  };

  const requestBody={
    lob_id: window.location.pathname.split("/")[2],
    payment_source_id: window.location.pathname.split("/")[3],
  }

    axios.post(`${host}/api/web/generateLinkGenOrder/${enc_data}`,requestBody,{
      headers:headers
    } )
    .then(function (response) {
      let data=response.data
      if(!data.success){
        data.error.map((val)=>{
          toast.error(val)
        })
        return
      }
     let url=JSON.stringify(response.data.response.links.web_url)
      window.location.assign(data.response.links.web_url);
    });
    
}


